import React, { useEffect, useMemo } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";

import APICtx, { useApi } from '#api'
import Screen, { TplWSidebar as Tpl } from '#Template'

import Ind40MachineListPage from './machine/List'
import Ind40MachineDatesPage from './plans/Dates'
import Ind40MachinePlansPage from './plans/Plans'
import Ind40ProgramTree from './programs/Tree'

import RecipeList from './ops/RecipeList'
import RecipePhaseList from './ops/RecipePhaseList'
import RecipePhaseWasteList from './ops/RecipePhaseWasteList'
import RecipePhaseRequirementList from './ops/RecipePhaseRequirementList'

import ODLList from './ops/odl/List'
import ODLPhasesSetup from './ops/ODLPhasesSetup'
import ODLPhasesManage from './ops/ODLPhasesManage'
import MgmTimers from './ops/MgmTimers'

import JobList from './ops/job/List'

import StockItemList from './ops/stockItem/List'

import PhaseStats from './ops/PhaseStats'

import {
	faConveyorBelt,
	faListCheck,
	faConveyorBeltArm,
	faArrowProgress,
	faBooks,
} from '@fortawesome/pro-solid-svg-icons';
import { faBoxes } from '@fortawesome/pro-regular-svg-icons';

const Router = () => (
	<Routes>
		<Route path="/machine/plans/:machine/:date" element={<Ind40MachinePlansPage />} />
		<Route path="/machine/plans/:machine" element={<Ind40MachineDatesPage />} />
		<Route path="/machine/list" element={<Ind40MachineListPage />} />

		<Route path="/task/list" element={<Ind40ProgramTree />} />

		<Route path="/ops/recipes/:recipe/phases/:phase/stats" element={<PhaseStats />} />
		<Route path="/ops/recipes/:recipe/phases/:phase/wastes" element={<RecipePhaseWasteList />} />
		<Route path="/ops/recipes/:recipe/phases/:phase/requirements" element={<RecipePhaseRequirementList />} />
		<Route path="/ops/recipes/:recipe/phases" element={<RecipePhaseList />} />
		<Route path="/ops/recipes" element={<RecipeList />} />

		<Route path="/ops/jobs" element={<JobList />} />
		<Route path="/ops/stock-items" element={<StockItemList />} />

		<Route path="/ops/odl/:odl/odl-phases/:odlPhase/timers-mgm" element={<MgmTimers />} />
		<Route path="/ops/odl/:odl/phases/setup" element={<ODLPhasesSetup />} />
		<Route path="/ops/odl/:odl/phases/manage" element={<ODLPhasesManage />} />
		<Route path="/ops/odl" element={<ODLList />} />

		<Route path="/" element={<JobList />} />
	</Routes>
)

const Blank = () => (
	<Tpl title="Foresti e Suardi" />
)

const useMenu = (api) => useMemo(() => {
	const menu = []

	if(api.auth.chk('ind40'))
		menu.push([
			{ label:"Macchine ind 4.0", linkTo:"/machine/list", icon:faConveyorBelt },
			{ label:"Programmi", linkTo:"/task/list", icon:faListCheck },
		])

	const menuOps = []
	if(api.auth.chk('ops-user'))
		menuOps.push(
			{ label:"Produzione", linkTo:"/ops/jobs", icon:faConveyorBeltArm },
		)
	if(api.auth.chk('ops-admin'))
		menuOps.push(
			{ label:"Gestione ODL", linkTo:"/ops/odl", icon:faArrowProgress },
			{ label:"Sequenza di lav.", linkTo:"/ops/recipes", icon:faBooks },
			{ label:"Articoli", linkTo:"/ops/stock-items", icon:faBoxes },
		)
	menuOps.length && menu.push(menuOps)

	return menu
}, [ api ])

export default function App() {
	const api = useApi()
	const menu = useMenu(api)

	return (
		<APICtx.Provider value={api}>
			<Screen toolAuth menu={menu}>
				{api.chkAuth() ? <Router /> : <Blank />}
			</Screen>
		</APICtx.Provider>
	)
}