import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText, SearchSelect, SearchAutoComplete } from '#Search'
import DataList from '#DataList'
import Fab from '#Fab'

import ODLEdit from './Edit'
import {
	Recipe,
	StockItem,
} from '../Widgets'

import {
	faEdit,
	faCheck,
	faGears,
	faPlay,
	faPlus,
	faQuestion,
	faTrash,
	faDiagramSubtask,
	faRecycle,
} from '@fortawesome/pro-regular-svg-icons'

const statusesOpts = [
	// { value:'active', label:'Attivi' },
	{ value:'setup', label:'In preparazione' },
	{ value:'running', label:'In corso' },
	{ value:'completed', label:'Completati' },
	{ value:'aborted', label:'Annullati' },
	{ value:'all', label:'Tutti' },
]
const statuses = [
	{ value:'setup', label:'In preparazione', icon:faGears },
	{ value:'running', label:'In corso', icon:faPlay },
	{ value:'completed', label:'Completato', icon:faCheck },
	{ value:'aborted', label:'Annullato', icon:faTrash },
]

const tableDef = [
	{ label:"Numero", field:'code' },
	{
		label: "Stato",
		content: row => statuses.find(status => row?.status === status.value)?.icon || faQuestion,
		tooltip: row => statuses.find(status => row?.status === status.value)?.label || 'Sconosciuto',
		type: 'icon',
	},
	{ label:"Ricetta", content:row => <Recipe _id={row?.recipe} /> },
	{ label:"Articolo", content:row => <StockItem _id={row?.stockItem} /> },
]

export default function ODLList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ editItem, setEditItem ] = useState()
	const [ filterCode, setFilterCode ] = useState('')
	const [ filterItem, setFilterItem ] = useState('')
	const [ filterStatus, setFilterStatus ] = useState(statusesOpts[0].value)

	const fetchItemOptions = async filterString =>
		api.call('ops/stock-item/list-options', { filterString })
	const fetchItemLabel = async _id => {
		const item = await api.call('ops/stock-item/get', { _id })
		return item?.code + ' - ' + item?.description?.split('\n')?.[0]
	}

	const handleActivate = ({ _id, code, handleRowRefresh }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler attivare l\'ODL "' + code + '"?',
			onConfirm: () => api.call('ops/odl/activate', { _id })
				.then(handleRowRefresh),
		})
	const handleDelete = ({ _id, code, handleRowRefresh }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler annullare l\'ODL "' + code + '"?',
			onConfirm: () => api.call('ops/odl/abort', { _id })
				.then(handleRowRefresh),
		})
	const handleReactivate = ({ _id, code, handleRowRefresh }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler riattivare l\'ODL "' + code + '"?',
			onConfirm: () => api.call('ops/odl/reactivate', { _id })
				.then(handleRowRefresh),
		})

	const handleRefresh = () =>
		api.call('ops/odl/list', { filterCode, filterStatus, filterItem }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ filterCode, filterStatus, filterItem ])

	const handleEditClose = async () =>
		setEditItem(null)

	const getRow = _id => api.call('ops/odl/get', { _id })
	const defBtns = [
		{
			icon: faDiagramSubtask,
			label: 'Configura fasi',
			linkTo: row => '/ops/odl/' + row._id + '/phases/setup',
			show: row => row.status === 'setup',
		},
		{
			icon: faDiagramSubtask,
			label: 'Mostra fasi',
			linkTo: row => '/ops/odl/' + row._id + '/phases/manage',
			show: row => row.status !== 'setup',
		},
		{
			icon: faCheck,
			label: 'Conferma ODL',
			onClick: handleActivate,
			show: row => row.status === 'setup',
		},
		{
			icon: faTrash,
			label: 'Annulla',
			onClick: handleDelete,
			show: row => [ 'setup', 'running' ].includes(row.status),
		},
		{
			icon: faRecycle,
			label: 'Riattiva',
			onClick: handleReactivate,
			show: row => [ 'aborted' ].includes(row.status),
		},
	]

	return (
		<Tpl title="Gestione ODL">
			<ODLEdit {...editItem} open={Boolean(editItem)} handleRefresh={handleRefresh} handleClose={handleEditClose} />
			<Search>
				<SearchText label="Cerca numero" name="src-code" onChange={setFilterCode} />
				<SearchAutoComplete
					label="Cerca articolo"
					name="src-item"
					onChange={setFilterItem}
					fetchOptions={fetchItemOptions}
					fetchLabel={fetchItemLabel}
				/>
				<SearchSelect label="Stato" name="src-status" onChange={setFilterStatus} options={statusesOpts} defaultValue={statusesOpts[0].value} />
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
			<Fab label="Crea ODL" icon={faPlus} onClick={() => setEditItem({ _id:null })} />
		</Tpl>
	)
}
