import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'

import RecipePhaseEdit from './RecipePhaseEdit';

import {
	faPlus,
	faEdit,
	faTrash,
	faUp,
	faDown,
	faRecycle,
	faDiamondExclamation,
	faTableCellsRowLock,
	faChartSimple,
} from '@fortawesome/pro-regular-svg-icons'

const tableDef = [
	{ label:"Nome", field:'label' },
	{ label:"Ordine", field:'weight' },
	{ label:"SAP", field:'sap', type:'boolean', trueIcon:faTableCellsRowLock, falseIcon:null, tooltip:row => row.sap || '-' },
]

export default function RecipePhaseList() {
	const api = useContext(APICtx)
	const { recipe } = useParams()
	const [ recipeData, setRecipeData ] = useState()
	const [ ids, setIds ] = useState([])
	const [ editItem, setEditItem ] = useState()

	const handleDelete = ({ _id, label }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler eliminare la fase "' + label + '"?',
			onConfirm: () => api.call('ops/phase/delete', { _id })
				.then(handleRefresh),
		})
	const handleMoveBefore = ({ _id }) =>
		api.call('ops/phase/move-before', { _id }).then(handleRefresh)
	const handleMoveAfter = ({ _id }) =>
		api.call('ops/phase/move-after', { _id }).then(handleRefresh)

	const handleRefresh = () =>
		api.call('ops/phase/list', { recipe }).then(setIds)
	useEffect(() => {
		handleRefresh()
		api.call('ops/recipe/get', { _id:recipe }).then(setRecipeData)
	}, [])

	const isRW = recipeData && !recipeData.sap

	const handleEditClose = async () =>
		setEditItem(null)

	const getRow = _id => api.call('ops/phase/get', { _id })
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica nome',
			onClick: setEditItem,
			show: isRW,
		},
		{
			icon: faUp,
			label: 'Anticipa fase',
			onClick: handleMoveBefore,
			show: isRW,
		},
		{
			icon: faDown,
			label: 'Posticipa fase',
			onClick: handleMoveAfter,
			show: isRW,
		},
		{
			icon: faRecycle,
			label: 'Gestisci scarti',
			linkTo: row => '/ops/recipes/' + recipe + '/phases/' + row._id + '/wastes',
		},
		{
			icon: faDiamondExclamation,
			label: 'Gestisci requisiti',
			linkTo: row => '/ops/recipes/' + recipe + '/phases/' + row._id + '/requirements',
		},
		{
			icon: faChartSimple,
			label: 'Visualizza statistiche',
			linkTo: row => '/ops/recipes/' + recipe + '/phases/' + row._id + '/stats',
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
			show: isRW,
		},
	]

	const title = (recipeData?.label ? recipeData.label+' - ' : '') + 'Gestione fasi'
	return (
		<Tpl title={title} backTo="/ops/recipes">
			<RecipePhaseEdit {...editItem} open={Boolean(editItem)} handleRefresh={handleRefresh} handleClose={handleEditClose} />
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} />
			<Fab label="Crea fase" icon={faPlus} onClick={() => setEditItem({ _id:null, recipe })} />
		</Tpl>
	)
}
