import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimer } from '@fortawesome/pro-regular-svg-icons';

export const Resource = ({ resource }) => {
	const api = useContext(APICtx)
	const [ label, setLabel ] = useState('-')

	useEffect(() => {
		resource && api.auth.appGroupGet(resource)
			.then(group => setLabel(group.displayName || group.address))
	}, [ resource ])

	return label
}

export const ODL = ({ odl }) => {
	const api = useContext(APICtx)
	const [ label, setLabel ] = useState('-')

	useEffect(() => {
		odl && api.call('ops/odl/get', { _id:odl })
			.then(odl => setLabel(odl.code))
	}, [ odl ])

	return label
}

export const Recipe = ({ _id }) => {
	const api = useContext(APICtx)
	const [ label, setLabel ] = useState('-')

	useEffect(() => {
		_id && api.call('ops/recipe/get', { _id })
			.then(result => setLabel(result?.label || '-'))
	}, [ _id ])

	return label
}

export const StockItem = ({ _id }) => {
	const api = useContext(APICtx)
	const [ label, setLabel ] = useState('-')

	useEffect(() => {
		_id && api.call('ops/stock-item/get', { _id })
			.then(result => setLabel(result?.code || '-'))
	}, [ _id ])

	return label
}

export const gfxTimer = (timer) => {
	let seconds = timer > 1 ? timer : 0

	const days = seconds >= 86400 ? Math.floor(seconds / 86400) : 0
	seconds %= 86400

	const hours = seconds >= 3600 ? Math.floor(seconds / 3600) : 0
	seconds %= 3600

	const minutes = seconds >= 60 ? Math.floor(seconds / 60) : 0
	seconds %= 60

	const timerStrings = []
	days && timerStrings.push(days + 'gg')
	hours && timerStrings.push(hours + 'h')
	minutes && timerStrings.push(minutes + 'm')
	seconds && timerStrings.push(seconds + 's')
	return timerStrings.length ? timerStrings.join(' ') : '0s'
}

const TimerDisplay = ({ label, timer, timerStarted }) => {
	const icon = timerStarted ? <FontAwesomeIcon icon={faTimer} size="lg" /> : ''
	const counter = timer === null ? '' : gfxTimer(timer)
	return (
		<Typography variant='body2'>
			{icon} {label}: {counter}
		</Typography>
	)
}

export const Timer = ({ timersStarted, timerTotal, prepTimerTotal }) => {
	const [ prepTimer, setPrepTimer ] = useState(null)
	const [ timer, setTimer ] = useState(null)

	const refreshTimer = () => {
		const total = timerTotal || 0
		const prepTotal = prepTimerTotal || 0

		let running = 0
		let prepRunning = 0
		timersStarted?.forEach(timer => {
			if(timer.type === 'main')
				running += (+new Date() - timer.ts)
			if(timer.type === 'preparation')
				prepRunning += (+new Date() - timer.ts)
		})
		// const running = timerStarted ? (+new Date() - timerStarted) : 0
		setTimer(
			Math.round((total + running)/1000)
		)

		// const prepRunning = prepTimerStarted ? (+new Date() - prepTimerStarted) : 0
		setPrepTimer(
			Math.round((prepTotal + prepRunning)/1000)
		)
	}

	useEffect(() => {
		refreshTimer()
		const intervalId = setInterval(() => {
			refreshTimer()
		}, 1000)

		return () => clearInterval(intervalId)
	}, [ timerTotal, prepTimerTotal, timersStarted ])

	return [
		<TimerDisplay label="Preparazione" timer={prepTimer} timerStarted={false} />,
		<TimerDisplay label="Lavorazione" timer={timer} timerStarted={false} />,
	]
}
